import React from 'react';
import { slugify } from '../../../utils/helpers'

export const Postcode = (props) => {
    const { field, value, handleChange } = props
    const { type, name, placeholder, required, className, conditional } = field
    if (conditional && !value[conditional]) return null
    return (
        <div className={`${className}${value[slugify(name)] ? ' active' : ''}${required ? ' required' : ''}`}>
            <label htmlFor={name}>{placeholder}</label>
            <input name={slugify(name)} id={slugify(name)} max="9999" pattern="[0-9]{5}" type="number" placeholder={placeholder} required={required ? true : false} onChange={handleChange} value={value[slugify(name)] ? value[slugify(name)] : ''} />
        </div>
    )
}
