import React, { Component } from 'react';
import fetch from 'isomorphic-fetch';
import { StaticQuery, graphql, navigate } from 'gatsby';
import { objectToQuerystring, slugify, decodeEntities } from '../../utils/helpers'
import * as FormFields from './Fields';
import FaCircleNotch from '../../images/icons/FaCircleNotch'
import { FaExclamation } from '../../images/icons/FaExclamation'
import ls from 'local-storage'
import loadable from '@loadable/component'
import RenderContent from '../RenderContent';
import ReCAPTCHA from "react-google-recaptcha";

class Index extends Component {
  
  recaptchaRef = React.createRef();

  constructor(props) {
    

    const { thankYou = null, data, id = null, leadFormPass } = props
    const { siteSettings, allWordpressWpForm } = data

    let settings = id && allWordpressWpForm && allWordpressWpForm.nodes.filter(i => i['wordpress_id'] === parseInt(id) )
    settings = settings && settings[0] && settings[0].formJson && settings[0].formJson
    
    let leadPass = leadFormPass ? leadFormPass : {};
    leadPass['location'] = typeof window !== `undefined` ? window.location.href : 'Server'
    leadPass['recipientAddress'] = settings && settings.recipientAddress ? settings.recipientAddress : null
    leadPass['formId'] = id
    
    super(props);
    this.state = {
      error: null,
      loading: false,
      submitted: false,
      id: id,
      siteKey: siteSettings.options.googleSiteKey,
      thankYou: thankYou,
      settings: settings && settings.formFields && settings.formFields,
      disclaimer: settings.disclaimer,
      lead: leadPass
    }
  }

  onSubmit = async(e) => {
    e.preventDefault();
    const token = await this.recaptchaRef.current.executeAsync();
    if (token) {
      this.handleSubmit(e);
    }
  }

  handleSubmit = () => {  
    this.setState({ loading: true });
    window.dataLayer = window.dataLayer || [];
    const leadFunctionUrl = `${process.env.GATSBY_WP_ADDRESS}/wp-json/leads/v2/submit`
    const appSecret = process.env.GATSBY_WEB_APP_SECRET
    //Add recipient
    const { lead } = this.state
    const { location } = lead;
    
    fetch(leadFunctionUrl, {
      method: 'POST',
      body: JSON.stringify({secret: appSecret, lead: lead})
    })
    .then(response => {
        if (response.status === 200) {
          window.dataLayer.push({
          'event': 'form_submit_success',
          'formLocation': location
          });
          this.setState({ loading: false, submitted: true, lead: {} });
          this.props.thankYou && this.props.thankYou !== '' && navigate(this.props.thankYou)
          ls.set('quote', null)
        } else {
          this.setState({ loading: false, error: true });
        }
    })
    .catch(error => {
      console.error('submitForm Error', error)
      this.setState({ loading: false, error: true });
    });
  }

  //Form change
  handleChange(e) {
      let lead = this.state.lead
      //If file
      if (e.target.type === 'file') {

        let fileName = `lead-file-${e.target.name}`
        let file = e.target.files[0];
        lead[fileName] = { data: '', file: '' }

        if (file) {
          const reader = new FileReader(file);
          reader.readAsDataURL(file);
          reader.onload = () => {
            // set image and base64'd image data in component state
            lead[fileName].data = reader.result
            lead[fileName].file = file.name
          }
        }
      } else if ( e.target.type === 'checkbox' ) {
        //Toggle value on off
        lead[e.target.name] === e.target.value ? lead[e.target.name] = '' : lead[e.target.name] = e.target.value
      } else {
        //All other fields
        lead[e.target.name] = e.target.value
      }
      this.setState({lead})
  }

  render() {
    const { loading, submitted, error, lead, id, settings, siteKey, disclaimer } = this.state
    const { submitText, thankYouContent, introContent } = this.props  

    if (!id) return 'No form id!'
    if (!settings) return 'There is a problem with the JSON!';

    if ( submitted ) return <div className="lead-form thank-you">{thankYouContent ? <RenderContent className="thanks" content={thankYouContent} /> : <><h3>Thank you, your request has been sent!</h3><p>A member of our passionate team will be back to you shortly to start your design journey. Let’s bring your venue vision to life!</p></>}</div>

    const formatComponentName = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const loadableFields = ['QuoteList']

    return (
      <div className="lead-form">
        {introContent && <RenderContent content={introContent}/>}
        {error && <div className="error"><div>There was a error <FaExclamation /></div></div>}
        {loading && <div className="loading"><div><FaCircleNotch className="spin" /> Loading...</div></div>}
        <form onSubmit={e => this.onSubmit(e)} className="form">
          <div className="form-inner">
            {settings.map((field, index) => {
              const fieldName = formatComponentName(field.type)
              const fieldPath = `./Fields/${fieldName}.js`;
              const  FormComponent = loadableFields.includes(fieldName) ? loadable(props => import(`${fieldPath}`)) : FormFields[fieldName]
              //Add quote to lead
              if (field.type === 'quoteList') {
                this.state.lead['quote'] = ls.get('quote')
              }
              return (
                <FormComponent key={index} field={field} value={lead} handleChange={e => this.handleChange(e)} />
              )
            })}
            <div>
              <button className={`submit`} aria-label={decodeEntities(submitText)} dangerouslySetInnerHTML={{__html: decodeEntities(submitText) }} />
              {disclaimer && <p className="disclaimer" dangerouslySetInnerHTML={{__html: decodeEntities(disclaimer) }}/ >}
              <ReCAPTCHA
                size="invisible"
                ref={this.recaptchaRef}
                className="invisible-recaptcha"
                style={{ opacity: 0 }} // we don't want the recaptcha icon to appear across the site so visibly
                sitekey={siteKey}
              />
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressWpSettings {
          title
          siteUrl
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            googleSiteKey
          }
        },
        allWordpressWpForm {
          nodes {
            wordpress_id
            formJson {
              recipientAddress
              disclaimer
              formFields {
                type
                required
                placeholder
                conditional
                options {
                  label
                  value
                }
                name
                className
                accept
              }
            }
          }
        }
      }
    `}
    render={data => <Index data={data} {...props} />}
  />
)
