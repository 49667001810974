import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import RenderContent from '../RenderContent'
import Leads from '../Leads'
import { ImagePass } from '../Image/ImagePass'
import './NewsletterSignUp.scss'

export class NewsletterSignUpPass extends Component {
  constructor(props) {
    super(props)
    this.state = {
      venue: null,
      lead: [],
      second: false,
    }
  }
  //Form change
  handleChange(value) {
    this.setState({ venue: null })
    this.setState({ venue: value })
  }
  render() {
    const { data } = this.props
    const { sectionTitle, intro, venue } = data.siteSettings.options
    return (
      <section className="newsletter">
        <div className="wrap">
          <div className="inner">
            {!this.state.second && (
              <div className="page-one">
                <span className="h4">{sectionTitle}</span>
                <RenderContent content={intro} />
                <div className="venue-nav">
                  <ul>
                    {venue &&
                      venue.map((item, index) => (
                        <li key={index}>
                          <button
                            onClick={e => this.handleChange(item)}
                            className={
                              this.state.venue?.name === item.name
                                ? 'selected'
                                : null
                            }
                          >
                            {item.name}
                          </button>
                        </li>
                      ))}
                  </ul>
                  <button
                    onClick={e => this.setState({ second: true })}
                    disabled={!this.state.venue}
                    className="next-button"
                  >
                    Next
                  </button>
                </div>
              </div>
            )}

            {this.state.second && (
              <div className="page-two">
                <span
                  className="h4"
                  dangerouslySetInnerHTML={{ __html: this.state.venue.intro }}
                />
                <div className="two-col">
                  <div className="col">
                    <div className="image">
                      <ImagePass src={this.state.venue.downloadThumb} />
                    </div>
                    <button
                      className="return"
                      onClick={e =>
                        this.setState({ venue: null, lead: [], second: false })
                      }
                    >
                      Change venue type
                    </button>
                  </div>
                  <div className="col">
                    <div className="lead">
                      <Leads
                        id={54}
                        introContent={this.state.venue.instructions}
                        thankYouContent={`${this.state.venue.thanks} <a href="${this.state.venue.download.source_url}" target="_blank" class="action pdf">Download</a>`}
                        submitText="Subscribe"
                        leadFormPass={{
                          venue: this.state.venue,
                          venuName: this.state.venue.name,
                          'newsletter-subscribe': true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    )
  }
}

export const NewsletterSignUp = props => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              venue {
                download {
                  source_url
                }
                downloadThumb {
                  localFile {
                    childImageSharp {
                      fixed(width: 303, quality: 100) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
                instructions
                intro
                name
                thanks
              }
              intro
              sectionTitle
            }
          }
        }
      `}
      render={data => <NewsletterSignUpPass {...props} data={data} />}
    />
  )
}
